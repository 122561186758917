import React, {
  ReactElement,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react'
import { graphql } from 'gatsby'
import Slider from 'react-slick'
import './PropertyConstruction.scss'
import Moment from 'react-moment'

import next from '../../assets/images/next.png'
import back from '../../assets/images/back.png'
import Seo from '../../components/Seo/Seo'
import { PropertyDataTypes } from '../Amenities/Amenities'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import loadable from '@loadable/component'

const Layout = loadable(() => import('../../components/Layout/Layout'))
const PropertyNavigationSliced = loadable(() =>
  import('../../components/PropertyNavigationSliced/PropertyNavigationSliced'),
)

type ConstructionPropTypes = {
  data: {
    propertyData: PropertyDataTypes
  }
}

const PropertyConstruction = ({
  data: { propertyData },
}: ConstructionPropTypes): ReactElement => {
  const towerImages = useState(
    propertyData.towers.map((construction) =>
      construction?.constructionUpdates?.imageGallery?.map((url) => url.url),
    ),
  )
  const towerUpdates = useState(
    propertyData.towers.map(
      (construction) => construction?.constructionUpdates,
    ),
  )

  const [imageGallery, setImageGallery] = useState<Array<string>>([])
  useEffect(() => {
    const container: Array<string> = []
    towerImages[0].map((val) => val?.map((url) => container.push(url)))
    setImageGallery(container)
    // eslint-disable-next-line
  }, [propertyData])

  const sliderRef = useRef<Slider>(null)
  const [currentSlide, setCurrentSlide] = useState(0)

  const [displayConstructionImages, setDisplayConstructionImages] = useState(
    imageGallery?.map((image) => image) || [],
  )
  useEffect(() => {
    setDisplayConstructionImages(imageGallery)
  }, [imageGallery])

  const handleBeforeChange = useCallback(
    (oldIndex: number, newIndex: number) => {
      setCurrentSlide(newIndex)
    },
    [],
  )

  const nextImage = useCallback(() => {
    if (sliderRef?.current?.slickNext) {
      sliderRef.current.slickNext()
    }
  }, [sliderRef])

  const prevImage = useCallback(() => {
    if (sliderRef?.current?.slickPrev) {
      sliderRef.current.slickPrev()
    }
  }, [sliderRef])

  const goToSlide = useCallback(
    (index: number) => {
      if (sliderRef?.current?.slickGoTo) {
        sliderRef.current.slickGoTo(index)
      }
    },
    [sliderRef],
  )

  const seoTitle =
    propertyData.towers[0]?.constructionUpdates?.seoConstructionPlanTitle
  const defaultTitle = `${propertyData.name} | Construction Updates`

  const seoDescription =
    propertyData.towers[0]?.constructionUpdates?.seoConstructionPlanDescription

  return (
    <Layout>
      <Seo
        title={seoTitle ? seoTitle : defaultTitle}
        jsonData={{
          '@type': 'Apartment',
          description: seoDescription
            ? seoDescription
            : propertyData.overviewDefault,
        }}
      />
      <div className="construction">
        <PropertyNavigationSliced
          propertyType={propertyData.propertyType}
          propertySlug={propertyData.slug}
          propertyLocation={propertyData.location}
        />
        <div className="construction-info">
          <h4 className="title">Construction Updates</h4>
          <h4 className="date">
            As of <Moment format="MMMM/DD/YYYY" />
          </h4>
          <h4
            className="description"
            dangerouslySetInnerHTML={{
              __html:
                propertyData.towers[0]?.constructionUpdates?.details || '',
            }}
          />
          <div>
            {towerUpdates[0].reverse()?.map((update, key) => {
              return (
                <div key={key}>
                  <h4 className="completion">
                    Completion Date : {update?.constructionEndDate}
                  </h4>
                  {update?.constructions?.map((details, keyDetails) => {
                    return (
                      <div key={keyDetails} className="details">
                        <h4 className="details-title">
                          {details.constructionUpdate}
                        </h4>
                        <h4 className="details-description">
                          {details.details}
                        </h4>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
        <div className="construction-image-slider">
          <Slider
            swipeToSlide={false}
            arrows={false}
            dots={false}
            beforeChange={handleBeforeChange}
            fade
            easing="ease"
            className="banner-slider"
            ref={sliderRef}
          >
            {displayConstructionImages.map((image) => (
              <LazyLoadImage
                key={image}
                className="slider-image"
                src={image}
                alt=""
              />
            ))}
          </Slider>
          <div className="nav-button-container">
            <button
              type="button"
              className="nav"
              onClick={(): void => prevImage()}
            >
              <img src={back} alt="backIcon" />
            </button>
            <button
              type="button"
              className="nav"
              onClick={(): void => nextImage()}
            >
              <img src={next} alt="nextIcon" />
            </button>
          </div>
          <div className="nav-image-container">
            <h4>Gallery</h4>
            <div className="container">
              {displayConstructionImages.map((image, index) => (
                <button
                  type="button"
                  key={image}
                  onClick={(): void => goToSlide(index)}
                >
                  <LazyLoadImage
                    src={image}
                    alt=""
                    className={`slider-nav${
                      currentSlide === index ? ' active' : ''
                    }`}
                  />
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PropertyConstruction

export const pageQuery = graphql`
  query PropertyConstructionQuery($id: String!) {
    propertyData: property(id: { eq: $id }) {
      ...PropertyPageFields
    }
  }
`
